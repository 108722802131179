<template>
    <div class="welfareDetails" v-if="itemData">
        <HeadBar :titleName="itemData.title"></HeadBar>
        <div class="content oepnX">
            <div class="contentImg">
                <ImgDecypt :src="itemData['cover']" />
            </div>
            <div class="contentTxt" v-text="itemData.content">
            </div>
            <div class="contentBtn" @click="goAdv(itemData)">
                <div class="participationBtn">
                    <span>立即参与</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadBar from "@/components/HeadBar";
import ImgDecypt from "@/components/ImgDecypt";
import {jumpAdv} from "@/utils/getConfig";
import {queryRights} from "@/api/user";
import {queryAnnougetById} from "@/api/welfare";
export default {
  name: "WelfareDetails",
    components:{
        HeadBar,
        ImgDecypt
    },
    data(){
      return {
          itemData:null,
          id:"",
      }
    },
    async mounted() {
        let query = this.$route.query;
        if (query) {
            // this.itemData = JSON.parse(query.item);
            this.id = query.id;

            let ret = await this.$Api(queryAnnougetById, {
                id: this.id,
            });
            if(ret.code == 200){
                this.itemData = ret.data;
            }
        }

        let res = await this.$Api(queryRights);
        if (res && res.code == 200) {
            let couponList = res.data.watchReadCoupon || [];
            this.couponNum = couponList.length;
        }
    },methods:{
        goAdv(item) {
            // console.log(item, "--------------")
            jumpAdv(item);
        }
    }
}
</script>

<style lang="scss" scoped>
    .welfareDetails{
        height: 100%;
        .content{
            height: calc(100vh - 44px);
            padding: 12px 12px 0 12px;
            //margin-top: 12px;
            overflow-y: auto;

            .contentImg{
                height: 140px;
                background: rgb(27,22,76);
                border-radius: 6px;

                /deep/ .van-image{
                    border-radius: 6px;
                }

                /deep/ .van-image__img{
                    border-radius: 6px;
                }
            }

            .contentTxt{
                white-space: pre-wrap;
                margin-top: 12px;
                font-size: 15px;
                color: rgb(0,0,0);
                margin-bottom: 50px;
            }

            .contentBtn{
                width: calc(100% - 24px);
                position: fixed;
                bottom: 25px;
                display: flex;
                justify-content: center;

                .participationBtn{
                    width: 162px;
                    height: 42px;
                    border-radius: 30px;
                    background: rgb(113,69,231);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    color: #FFFFFF;
                    font-weight: bold;
                }
            }
        }
    }
</style>
